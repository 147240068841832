import * as dd from 'dingtalk-jsapi';
import consumer_dingdingLogin from '@/lib/data-service/haolv-default/consumer_dingdingLogin';
import set_token from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv-app/set_token";
import get_user_info from "@/lib/common-service/get_user_info";
import {awaitWrap} from "@/page/admin/company-management/staff-management/common/unit";

export default {
    data() {
        return {
            loading: false,
            corpId: '',
        }
    },
    props: {},
    components: {},
    created() {
        this.loading = true;
    },
    mounted() {
        console.log(dd, dd.env);
        this.corpId = this.$route.query.corpId || '';
        if (dd.env.platform === "notInDingTalk") {
            console.log("不在钉钉环境");
            this.loading = false;
            this.$message.warning('不在钉钉环境');
            return;
        }
        dd.setStorage({
            data: this.corpId,
            key: 'corpId',
        })
            // 设置导航栏标题
        dd.biz.navigation.setTitle({
            title: '铨成商旅',
            onSuccess() {},
            onFail() {},
        }).catch((err) => { console.log(`${err }`); });

        this._login();
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        _login() {
            let _this = this;
            //alert(`公司corpId：${this.corpId}`);
            dd.getAuthCode({
                // corpId: 'ding09a595d36cde39d9acaaa37764f94726',
                corpId: _this.corpId,
                success: async (res) => {
                    const { code } = res;
                    //alert(`成功code:${code}`)
                    const params = {corpId: _this.corpId, dingdingCode: code};
                    //alert(JSON.stringify(params));
                    // 调用code换token接口
                    let [lErr, lRes] = await awaitWrap(consumer_dingdingLogin(params));
                    if (lErr) {
                        //alert('登录失败')
                        _this.$message.warning(lErr.data.msg);
                        return
                    }
                    //alert(`登录成功: ${lRes.datas.access_token}`);
                    set_token({token: lRes.datas.access_token});
                    // 关闭loading
                    _this.loading = false;
                    _this.$message.success('登录成功');
                    // 保存token并跳转到首页
                    get_user_info({
                        clear_cache: true,
                    })
                    this.$router.replace({
                        name: `redirect`,
                    });
                },
                fail: () => {
                    _this.loading = false;
                },
                complete: () => {},
            });
        }
    }
}